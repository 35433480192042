* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

#root {
  min-height: 100vh;
  width: 100%;
}

button {
  cursor: pointer;
}

iframe {
  display: none;
}